import { d as _classCallCheck } from './_rollupPluginBabelHelpers-6bde239b.js';

var ModalButton = function ModalButton(title, cb) {
  _classCallCheck(this, ModalButton);

  this.title = title;
  this.cb = cb;
};

export { ModalButton as M };
