export default {
  "footer_company": "N.V. Interkim",
  "title": "BlueCherry IoT Platform",
  "header_logo": "bluecherry_logo_header.svg",
  "sidenav_logo": "bluecherry_sidenav_logo.svg",
  "sidenav_text": "<h1> <span>Connect.</span> <strong>Anything.</strong> <span>Anywhere.</span> </h1>",
  "company": "BlueCherry",
  "use_proxy": false,
  "show_footer_logo": false
}


